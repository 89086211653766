<script setup>
const home = await usePage("home");
const { isProduction } = useEnvironment();
</script>

<template>
  <div>
    <Seo
      v-if="isProduction && home?.content?.meta"
      :meta="home?.content?.meta"
    />
    <Page v-if="home?.content?.component" :blok="home?.content" />
  </div>
</template>
